import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import ComponentCard from '../../../components/ComponentCard';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import api from '../../../constants/api';

export default function Index() {
  const [employess, setEmployees] = React.useState([]);
  React.useEffect(() => {
    api
      .get('/location/getAllEmployees')
      .then((res) => {
        setEmployees(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSubmit = (value,object,type) =>{
    if(type === 'location'){
        object.location = value.currentTarget.textContent
    }else if(type === 'address'){
        object.address = value.currentTarget.textContent
    }else{
      object.color = value.currentTarget.textContent
    }
    Swal.fire({
        title: 'Are you sure?',
        text: "You want to update",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .post('/location/updateAddressLocation', object)
            .then((res) => {
              if (res.data.status === '200') {
                Swal.fire('Update!', 'Location is updated', 'success');
              }
            })
            .catch((err) => {
                console.log(err)
                Swal.fire('Failed!', 'Unable to update', 'error');
            });
        }
      });


  }
  return (
    <div>
      <BreadCrumbs />
      <Row>
        <Col md="12">
          <ComponentCard>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Address</th>
                  <th scope="col">Color</th>
                  <th scope="col"></th>
                  {/* <th scope="col">Type</th>
                  <th scope="col">Nick Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">DOB</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {employess &&
                  employess.map((tab,index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{tab.name}</td>
                        <td onBlur={(e) => {
                            handleSubmit(e, tab, 'location')
                          }} contentEditable>{tab.location}</td>
                        <td  onBlur={(e) => {
                            handleSubmit(e, tab, 'address')
                          }} contentEditable>{tab.address}</td>
                          <td  onBlur={(e) => {
                            handleSubmit(e, tab, 'color')
                          }} contentEditable>{tab.color}</td>
                          <td>
                            <div style={{backgroundColor:tab.color ? tab.color : '#000',width:50,height:50}}></div>
                          </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </ComponentCard>
        </Col>
      </Row>
    </div>
  );
}
