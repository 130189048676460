import React from 'react';
import { Button, Card, CardBody, Col, Row, Spinner, Table } from 'reactstrap';
import 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { serialize, unserialize } from 'php-serialize';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import api from '../../../constants/api';
import SectionOne from '../../../components/blueteam/editquotation/SectionOne';
import AddServiceSection from '../../../components/blueteam/editquotation/AddServiceSection';
import AdditionalService from '../../../components/blueteam/editquotation/AdditionalService';
import EditSpare from '../../../components/blueteam/editquotation/EditSpare';
import Constants from '../../../assets/fonts/Tajawal';

export default function Editquote() {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  window.pdfMake.vfs['Tajawal.ttf'] = Constants.font;
  const { id } = useParams();
  const [quote, setQuotationData] = React.useState();
  const [services, setServices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [preferredTimeSlots, setPreferredTimeSlots] = React.useState([]);
  const [spareParts, setSparePartsData] = React.useState([]);
  const [items, setItem] = React.useState([]);

  /* eslint-disable */
  const getQuotationById = () => {
    setLoading(true);
    api
      .post('/quotation/getQuotationById', { id })
      .then((res) => {
        setQuotationData(res.data.data);
        setServices(res.data.data?.services);
        class stdClass {
          constructor() {
            this.name = '';
            this.price = 0;
            this.per = 0;
            this.id = 0;
            this.total = 0;
            this.qty = 0;
          }
        }

        const sparePartsData = unserialize(res.data.data?.spare_parts, { stdClass });
        setSparePartsData(sparePartsData);
        const serviceItem = res.data.data?.services;
        /* eslint-disable */

        if (sparePartsData.length > 0) {
          sparePartsData.forEach((re) => {
            serviceItem.push({
              add_booking_name: re.name,
              add_booking_price:
                parseFloat(re.price || 0) +
                (parseFloat(re.per || 0) / 100) * parseFloat(re.price || 0),
              qty: re.qty,
              total: re.total,
              type: 'spare',
            });
          });
        }

        setItem(serviceItem);
        /* eslint-enable */
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Unable to get Quote data');
        setLoading(false);
      });
  };
  /* eslint-enable */
  const gettimeslot = async () => {
    api
      .post('/location/time_zones')
      .then((res) => {
        setPreferredTimeSlots(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = (event) => {
    if (event.target.name === 'phone') {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    setQuotationData({ ...quote, [event.target.name]: event.target.value });
  };
  React.useEffect(() => {
    getQuotationById();
    gettimeslot();
    return () => {
      getQuotationById();
      gettimeslot();
    };
  }, []);
  const deleteServicesFromBooking = (addService) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        addService.booking_id = id;
        addService.total_price =
          parseFloat(quote?.total_price) - parseFloat(addService.add_booking_price);
        api
          .post('/quotation/deleteServicesFromBooking', addService)
          .then((response) => {
            if (response.data.status === '200') {
              window.location.reload();
            } else {
              toast.warning('Unable to delete Service');
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error('Unable to delete Service');
          });
      }
    });
  };
  const handleDeleteSpareParts = (selectedObj) => {
    const removedSparePartByID = spareParts.filter((item) => item.id !== selectedObj.id);
    const cost = parseFloat(selectedObj.price) * parseFloat(selectedObj.qty);
    api
      .post('/quotation/addSpare', {
        id,
        total: parseFloat(quote?.total_price || 0) - parseFloat(selectedObj.total || 0),
        cost: parseFloat(quote?.cost || 0) - parseFloat(cost || 0),
        spare_parts: serialize(removedSparePartByID),
      })
      .then((res) => {
        console.log(res);
        toast.success('Spare Update');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Unable to Add Spare');
      });
  };
  const submitEditBooking = () => {
    let preferedDate = quote.prefered_date;
    if (quote.prefered_date.length === 10) {
      preferedDate = `${quote.prefered_date}T14:37:00.116+05:30`;
    }
    if (quote.prefered_time === '' || quote.prefered_date === '' || quote.payment_mode === '') {
      toast.error('الرجاء إدخال جميع المتطلبات الاساسية للحجز!');
    } else {
      setLoading(true);
      quote.prefered_date = preferedDate;
      api
        .post('/quotation/updateQuotationById', quote)
        .then((res) => {
          console.log(res);
          if (res.data.status === '200') {
            setLoading(false);
            toast.success('Booking Edit Done');
            window.location.reload();
          } else {
            toast.warning('Unable to edit booking');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Network Connection error');
        });
    }
  };
  function reverseWords(s) {
    /* eslint-disable */
    let ans = '';
    const strArray = s.split(' ');
    s = s.replace('(', ' ').replace(')', ' ');

    for (var i = strArray.length - 1; i >= 0; i--) {
      ans += strArray[i] + ' ';
    }

    return ans;
  }

  const generateQuote = () => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    pdfMake.fonts = {
      Tajawal: {
        normal: 'Tajawal.ttf',
        bold: 'Tajawal.ttf',
        italics: 'Tajawal.ttf',
        bolditalics: 'Tajawal.ttf',
      },
    };

    const arrayOfItem = [
      [
        {
          text: 'S.NO',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'DESCRIPTION',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'QTY',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'RATE',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },

        {
          text: 'AMOUNT',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'VAT 5%',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'NET AMOUNT',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
      ],
    ];
    let Total = 0;
    let totalWithoutTax = 0;
    let amot = 0;
    let vat = 0;

    let totalVat = 0;
    items.forEach((element, index) => {
      const myArray = element.add_booking_name.split('/');

      totalWithoutTax += parseFloat(element.add_booking_price, 10) * parseInt(element.qty || 1, 10);
      amot = parseFloat(element.add_booking_price, 10) * parseInt(element.qty || 1, 10);
      vat = 0.05 * amot;
      amot = amot + 0.05 * amot;
      Total += amot;
      totalVat += vat;
      arrayOfItem.push([
        {
          text: index + 1,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: myArray[0],
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: element.qty || 1,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'right',
        },
        {
          text: parseFloat(element.add_booking_price).toFixed(2),
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'right',
        },

        {
          text: (
            parseFloat(element.add_booking_price, 10) * parseInt(element.qty || 1, 10)
          ).toFixed(2),
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'right',
        },
        {
          text: vat.toFixed(2),
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'right',
        },
        {
          text: amot.toFixed(2),
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'right',
        },
      ]);
    });

    const dd = {
      pageMargins: [0, 0, 0, 0],
      pageSize: 'A4',
      background: [
        {
          image: Constants.image,
          width: 612,
        },
      ],
      content: [
        {
          margin: [0, 0, 0, 0],
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  text: '\n',
                  height: 20,
                  fillColor: '#325ea7',
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  textTransform: 'uppercase',
                },
              ],
            ],
          },
        },
        {
          margin: [20, 0, 10, 0],
          columns: [
            {
              text: 'TRN - 104138666300003',
              color: '#333',
              bold: true,
              fontSize: 13,
              alignment: 'right',
              margin: [0, 70, 0, 5],
            },
          ],
        },
        {
          margin: [20, 20, 50, 0],
          columns: [
            {
              text: 'Quotation',
              color: '#000',
              bold: true,
              decoration: 'underline',
              fontSize: 23,
              alignment: 'center',
              margin: [50, 0, 0, 5],
            },
          ],
        },
        {
          margin: [20, 0, 20, 0],
          columns: [
            {
              text: `To - ${
                quote?.name.length > 0 &&
                (quote?.name.match(/[a-z]/i) ? quote?.name : reverseWords(quote?.name))
              }`,
              color: '#325ea7',
              bold: true,

              fontSize: 12,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          margin: [20, 10, 20, 0],
          columns: [
            {
              text: `Booking ID - ${quote?.service_id}`,
              bold: true,
              color: '#333333',
              fontSize: 12,
              alignment: 'left',
            },
          ],
        },

        {
          margin: [20, 10, 20, 0],
          columns: [
            {
              text: ` Date - ${moment().tz('Asia/Dubai').format('DD-MM-YYYY')}`,
              bold: true,
              color: '#000',
              fontSize: 12,
              alignment: 'left',
            },
          ],
        },

        '\n',
        {
          margin: [20, 0, 20, 0],
          layout: {
            defaultBorder: false,
            hLineWidth: () => {
              return 1;
            },
            vLineWidth: () => {
              return 1;
            },
            hLineColor: (i) => {
              if (i === 1 || i === 0) {
                return '#bfdde8';
              }
              return '#eaeaea';
            },
            vLineColor: () => {
              return '#eaeaea';
            },
            hLineStyle: () => {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: () => {
              return 10;
            },
            paddingRight: () => {
              return 10;
            },
            paddingTop: () => {
              return 2;
            },
            paddingBottom: () => {
              return 2;
            },
            fillColor: () => {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['7%', '32%', '8%', '15%', '13%', '11%', '14%'],
            body: arrayOfItem,
          },
        },
        {
          margin: [20, 0, 5, 0],
          layout: {
            defaultBorder: false,
            hLineWidth: () => {
              return 1;
            },
            vLineWidth: () => {
              return 1;
            },
            hLineColor: () => {
              return '#eaeaea';
            },
            vLineColor: () => {
              return '#eaeaea';
            },
            hLineStyle: () => {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: () => {
              return 10;
            },
            paddingRight: () => {
              return 10;
            },
            paddingTop: () => {
              return 3;
            },
            paddingBottom: () => {
              return 3;
            },
            fillColor: () => {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Gross Amount',
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 3, 0, 3],
                },
                {
                  text: `${totalWithoutTax.toFixed(2) || 0} AED`,
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 3, 0, 3],
                },
              ],
            ],
          },
        },
        {
          margin: [20, 0, 5, 0],
          layout: {
            defaultBorder: false,
            hLineWidth: () => {
              return 1;
            },
            vLineWidth: () => {
              return 1;
            },
            hLineColor: () => {
              return '#eaeaea';
            },
            vLineColor: () => {
              return '#eaeaea';
            },
            hLineStyle: () => {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: () => {
              return 10;
            },
            paddingRight: () => {
              return 10;
            },
            paddingTop: () => {
              return 3;
            },
            paddingBottom: () => {
              return 3;
            },
            fillColor: () => {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Total Tax',
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 3, 0, 3],
                },
                {
                  text: `${totalVat.toFixed(2) || 0} AED`,
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 3, 0, 3],
                },
              ],
            ],
          },
        },

        {
          margin: [20, 0, 5, 0],
          layout: {
            defaultBorder: false,
            hLineWidth: () => {
              return 1;
            },
            vLineWidth: () => {
              return 1;
            },
            hLineColor: () => {
              return '#eaeaea';
            },
            vLineColor: () => {
              return '#eaeaea';
            },
            hLineStyle: () => {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: () => {
              return 10;
            },
            paddingRight: () => {
              return 10;
            },
            paddingTop: () => {
              return 3;
            },
            paddingBottom: () => {
              return 3;
            },
            fillColor: () => {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Grand Total',
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 3, 0, 3],
                },
                {
                  text: `${Total.toFixed(2) || 0} AED`,
                  bold: true,
                  fontSize: 12,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 3, 0, 3],
                },
              ],
            ],
          },
        },

        '\n\n',

        {
          margin: [20, 20, 20, 20],
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['100%'],

            body: [
              [
                {
                  style: 'bigger',
                  italics: false,
                  text: [
                    {
                      text: '\nTerms & Conditions\n',

                      style: 'header',
                      margin: [20, 0, 20, 0],
                    },

                    {
                      text: '\n1.Warranty cover bad installment and heat rejection rate.',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n2.Ceramics should be maintained every 6 months.',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n3.In the event that the car is washed outside Blueteam, the ceramic guarantee is void.',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n4.The warranty for the PPF is for the factory paint only, and it is considered void if the car was painted.',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n5.The PPF should only be removed By Blueteam to ensure that the paint is not damaged.',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n6.Blue Team is not responsible for damages on  installation and removal of the PPF if the vehicle was painted',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                    {
                      text: '\n7.For battery warranty card must be presented, otherwise the warranty will be considered void',
                      style: 'notesText',
                      margin: [20, 0, 20, 0],
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          margin: [0, 0, 10, 0],
          // style: 'name',

          alignment: 'center',
          table: {
            widths: ['40%', '40%', '20%'],
            body: [
              [
                { text: '' },
                { text: '' },
                {
                  fillColor: '#2e75b5',
                  text: reverseWords('توقيع المسؤول'),
                  margin: 0,
                  color: '#fff',
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          margin: [0, 0, 0, 0],
          // style: 'name',

          alignment: 'center',
          table: {
            widths: ['40%', '40%', '20%'],
            body: [
              [
                { text: '' },
                { text: '' },
                { text: user.user_retrive_name, margin: 0, color: '#000' },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 15,
        },
        notesTitle: {
          fontSize: 15,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        columnGap: 20,
        font: 'Tajawal',
        //font: 'Quicksand',
      },
      tableExample: {
        fillColor: '#2e75b5',
        color: '#fff',
      },
    };

    pdfMake.createPdf(dd).open();
  };
  return (
    <div>
      <div className="container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Card>
          <CardBody>
            <Button
              onClick={() => {
                generateQuote();
              }}
              color="dark"
              className="mb-3"
            >
              Generate Quote
            </Button>
            <SectionOne
              formData={quote}
              handleChange={handleChange}
              preferredTimeSlots={preferredTimeSlots}
            ></SectionOne>
            {quote?.quote_status == '0' && (
              <Row className="mt-3">
                <Col lg={3}>
                  {!loading ? (
                    <Button
                      onClick={() => {
                        submitEditBooking();
                      }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Spinner color="blue"></Spinner>
                  )}
                </Col>
              </Row>
            )}
            <hr></hr>
            <h4>Services</h4>
            <AddServiceSection
              disabled={quote?.quote_status != '0'}
              id={id}
              toast={toast}
              price={quote?.total_price}
            ></AddServiceSection>
            <AdditionalService
              disabled={quote?.quote_status != '0'}
              id={id}
              toast={toast}
              price={quote?.total_price}
            ></AdditionalService>
            <Table className="mt-3" bordered>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Car Type</th>
                  <th>Car Number</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {services &&
                  services.map(
                    (s, i) =>
                      !s.type && (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{s.add_booking_name}</td>
                          <td>{s.add_car_type}</td>
                          <td>{s.add_car_no}</td>
                          <td>{s.add_booking_price}</td>
                          <td>{0.05 * parseFloat(s.add_booking_price)}</td>
                          <td>
                            {0.05 * parseFloat(s.add_booking_price) +
                              parseFloat(s.add_booking_price)}
                          </td>
                          <td>
                            <Button
                              disabled={quote?.quote_status != '0'}
                              onClick={() => {
                                deleteServicesFromBooking(s);
                              }}
                              color="danger"
                            >
                              -
                            </Button>
                          </td>
                        </tr>
                      ),
                  )}
              </tbody>
            </Table>
            <hr></hr>
            <h4>Spare</h4>
            <EditSpare
              selectedSpare={spareParts}
              id={id}
              toast={toast}
              total={quote?.total_price}
              cost={quote?.cost}
              disabled={quote?.quote_status != '0'}
            ></EditSpare>
            <Table bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Percentage</th>
                  <th>Total</th>
                  <th>Tax</th>
                  <th>With Tax</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {spareParts &&
                  spareParts.map((selectedRow) => (
                    <tr>
                      <td>{selectedRow?.name}</td>
                      <td>{selectedRow?.price}</td>
                      <td>{selectedRow?.qty}</td>
                      <td>{selectedRow?.per}</td>
                      <td>{selectedRow?.total}</td>
                      <td>{parseFloat(selectedRow?.total) * 0.05}</td>
                      <td>
                        {parseFloat(selectedRow?.total) * 0.05 + parseFloat(selectedRow?.total)}
                      </td>
                      <td>
                        <Button
                          disabled={quote?.quote_status != '0'}
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDeleteSpareParts(selectedRow);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
