import React from 'react';
import { Row, Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import ComponentCard from '../../../components/ComponentCard';
import api from '../../../constants/api';
import PackagesOrdered from '../../../components/dashboard/classicDashboard/PackagesOrdered';

const Apexcharts = () => {
  const [yearLoader, setYearLoader] = React.useState(false);
  const [option, setOption] = React.useState({
    colors: ['#745af2', '#eb489b', '#325ea7', '#9f9f9c'],
    chart: {
      fontFamily: "'Rubik', sans-serif",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      labels: {
        style: {
          cssClass: 'grey--text lighten-2--text fill-color',
        },
      },
    },
    yaxis: {
      title: {
        text: 'درهم',
        color: '#8898aa',
      },
      labels: {
        style: {
          cssClass: 'grey--text lighten-2--text fill-color',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter(val) {
          return ` ${val} درهم`;
        },
      },
    },
    grid: {
      borderColor: 'rgba(0,0,0,0.1)',
    },
    legend: {
      show: true,
      position: 'bottom',
      width: '50px',
      fontFamily: "'Montserrat', sans-serif",
      labels: {
        colors: '#8898aa',
      },
    },
  });
  const [data, setData] = React.useState([
    {
      name: 'Cash',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: 'Bank Transfer',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: 'Bank Card',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
    {
      name: 'Blueteam Card',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ]);
  const [zoneRange, setzoneRange] = React.useState({
    start: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  });
  const getPaymentCollection = (formalRange) => {
    setYearLoader(true);
    api
      .post('/reports/getPaymentCollection', formalRange)
      .then((res) => {
        const apiRes = res.data.data;
        const mainArray = [
          {
            name: 'Cash',
            data: [],
          },
          {
            name: 'Bank Transfer',
            data: [],
          },
          {
            name: 'Bank Card',
            data: [],
          },
          {
            name: 'Blueteam Card',
            data: [],
          },
        ];
        const empArray = [];
        apiRes.forEach((element) => {
          empArray.push(element.name);
          mainArray[0].data.push(element.cash);
          mainArray[1].data.push(element.bank_transfer);
          mainArray[2].data.push(element.bank_card);
          mainArray[3].data.push(element.paid_card);
        });
        setOption({
          colors: ['#745af2', '#eb489b', '#325ea7', '#9f9f9c'],
          chart: {
            fontFamily: "'Rubik', sans-serif",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '100%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: empArray,
            labels: {
              style: {
                cssClass: 'grey--text lighten-2--text fill-color',
              },
            },
          },
          yaxis: {
            title: {
              text: 'درهم',
              color: '#8898aa',
            },
            labels: {
              style: {
                cssClass: 'grey--text lighten-2--text fill-color',
              },
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            theme: 'dark',
            y: {
              formatter(val) {
                return ` ${val} درهم`;
              },
            },
          },
          grid: {
            borderColor: 'rgba(0,0,0,0.1)',
          },
          legend: {
            show: true,
            position: 'bottom',
            width: '50px',
            fontFamily: "'Montserrat', sans-serif",
            labels: {
              colors: '#8898aa',
            },
          },
        });
        setData(mainArray);
        setYearLoader(false);
      })
      .catch((error) => {
        setYearLoader(false);
        console.log(error);
      });
  };
  React.useEffect(() => {
    getPaymentCollection({
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    });
  }, []);
  const onDateRangeChange = (event, picker) => {
    setzoneRange({
      start: moment(picker.startDate).format('YYYY-MM-DD'),
      end: moment(picker.endDate).format('YYYY-MM-DD'),
    });
    getPaymentCollection({
      start: moment(picker.startDate).format('YYYY-MM-DD'),
      end: moment(picker.endDate).format('YYYY-MM-DD'),
    });
  };
  return (
    <div>
      <BreadCrumbs />
      <Row>
        <Col md="12">
          <DateRangePicker
            onEvent={onDateRangeChange}
            initialSettings={{
              startDate: moment().format('MM/DD/YYYY'),
              endDate: moment().format('MM/DD/YYYY'),
            }}
          >
            <button type="button" className="btn btn-success mb-2">
              SELECTED RANGE - {zoneRange.start} - {zoneRange.end}
            </button>
          </DateRangePicker>
          <ComponentCard title="Payment Collection Chart">
            {!yearLoader && <Chart options={option} series={data} type="bar" height="280" />}
          </ComponentCard>
        </Col>
        <Col md="12">
          <PackagesOrdered></PackagesOrdered>
        </Col>
      </Row>
    </div>
  );
};

export default Apexcharts;
