import { useState } from 'react';

function UserToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('bt_token');
    const userToken = tokenString;
    return userToken;
  };
  const [token, setToken] = useState(getToken());
  const saveToken = (userToken) => {
    localStorage.setItem('bt_token', userToken);
    setToken(userToken.token);
  };
  return {
    setToken: saveToken,
    token,
  };
}

export default UserToken;
