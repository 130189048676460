import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { PermifyProvider } from '@permify/react-role';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { AppProvider } from './context/AppContext';

const App = () => {
  ///const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <PermifyProvider>
      <AppProvider>
        <Suspense fallback={<Loader />}>
          <div
            className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
            dir={direction ? 'rtl' : 'ltr'}
          >
            <ThemeSelector />
            <Themeroutes></Themeroutes>
          </div>
        </Suspense>
      </AppProvider>
    </PermifyProvider>
  );
};

export default App;
