import React, { useRef } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import api from '../../../constants/api';

export default function AdditionalService({ id, toast, price,disabled }) {
  AdditionalService.propTypes = {
    id: PropTypes.any,
    toast: PropTypes.any,
    price: PropTypes.any,
    disabled:PropTypes.any
  };
  const [allpackage, setPackages] = React.useState();
  const carNumber = useRef();
  const [workpackage, setWorkPackage] = React.useState({
    name: '',
    car_type: 'four-wheeler',
    car_no: '',
    id: '',
    price: '',
    uid: '',
    time: 0,
  });
  const getAllPackages = () => {
    api
      .get('/workstation/getAllAdditionalServices')
      .then((res) => {
        setPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRowValueChange = (e) => {
    setWorkPackage({ ...workpackage, [e.target.name]: e.target.value });
  };
  const handleSubCategoryForPrice = (event) => {
    const selectCategory = event.target.value;

    let packagePrice = 0;
    const subCategoryById = allpackage.find(
      (subCategory) => subCategory.add_id === parseInt(selectCategory, 10),
    );
    if (workpackage.car_type === 'salon') {
      packagePrice = subCategoryById?.add_price_small;
    } else {
      packagePrice = subCategoryById?.add_price_big;
    }
    setWorkPackage({
      ...workpackage,
      id: selectCategory,
      name: `${subCategoryById?.add_name}/${subCategoryById?.add_name_arabic}`,
      price: packagePrice,
    });
  };

  const handleCarTypeForPrice = (cartype) => {
    let packagePrice;
    const subCategoryById = allpackage.find(
      (subCategory) => subCategory.add_id === parseInt(workpackage.id, 10),
    );

    if (cartype.target.value === 'salon') {
      packagePrice = subCategoryById?.add_price_small;
    } else {
      packagePrice = subCategoryById?.add_price_big;
    }
    setWorkPackage({
      ...workpackage,
      price: packagePrice,
      car_type: cartype.target.value,
    });
  };
  const addServiceToCategory = () => {
    if (
      workpackage.name &&
      workpackage.id &&
      workpackage.price
    ) {
      let userObject = localStorage.getItem('user');
      userObject = JSON.parse(userObject);
      workpackage.added_by = userObject.user_retrive_name;
      workpackage.booking_id = id;
      workpackage.total_price =
        parseFloat(price) + parseFloat(workpackage.price);
      console.log(workpackage);
      api
        .post('/quotation/addServiceToBooking', workpackage)
        .then((response) => {
          if (response.data.status === '200') {
            toast.success('Service added');
            window.location.reload();
          } else {
            toast.warning('Unable to add Service');
          }
        })
        .catch((err) => {
          console.log(err);
         
        });
    } else {
      toast.error('Name Price are required');
    }
  };
  React.useEffect(() => {
    getAllPackages();

    return () => {
      getAllPackages();
    };
  }, []);

  return (
    <>
      <Row className="pb-2 pt-2" style={{ margin: 0, background: 'aliceblue', borderRadius: 5 }}>
        <Col lg={3} sm={12}>
          <Label for="">Package</Label>
          <Input
            type="select"
            id="main-category"
            name="mainCategory"
            className="form-control"
            onChange={(e) => {
              handleSubCategoryForPrice(e);
            }}
          >
            <option>Select Additional</option>
            {allpackage &&
              allpackage.map((category) => {
                return (
                  <option
                    value={category?.add_id}
                  >{`${category?.add_name} / ${category?.add_name_arabic}`}</option>
                );
              })}
          </Input>
        </Col>
        <Col lg={2} sm={12}>
          <Label for="">Car Type</Label>
          <Input
            type="select"
            name="car_type"
            id="car_type"
            className="form-control"
            onChange={handleCarTypeForPrice}
            defaultValue="four-wheeler"
          >
            <option value="four-wheeler">Four Wheeler</option>
            <option value="salon">Salon</option>
          </Input>
        </Col>
        <Col lg={3} sm={12}>
          <Label for="">Price</Label>
          <input
            type="text"
            name="price"
            defaultValue={workpackage.price}
            className="form-control"
            placeholder="Price"
            onChange={handleRowValueChange}
          />
        </Col>
        <Col lg={3}>
          <Label for="">Car No</Label>
          <input
            type="text"
            name="car_no"
            className="form-control"
            placeholder="Car No"
            onChange={handleRowValueChange}
            ref={carNumber}
          />
        </Col>
        <Col lg={1} sm={12}>
          <button
          disabled={disabled}
            type="button"
            className="btn btn-primary"
            style={{ marginTop: 32 }}
            onClick={() => {
                addServiceToCategory();
            }}
          >
            Add
          </button>
        </Col>
      </Row>
      
    </>
  );
}
