import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBiaeb8JekL1yAnPU-PxLMTQFl6uKR0Tfk",
  authDomain: "blueteam-serviceman.firebaseapp.com",
  databaseURL: "https://blueteam-serviceman.firebaseio.com",
  projectId: "blueteam-serviceman",
  storageBucket: "blueteam-serviceman.appspot.com",
  messagingSenderId: "945339555258",
  appId: "1:945339555258:web:2f59eb041b40e776971183",
  measurementId: "G-RQJPNR70W6"
};
/* eslint-disable */
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const getTokenFunc = async () => {
  return getToken(messaging, {vapidKey: 'BFSmD4DAc4is-hRevZshHXP2XXsDq93XCo0UPMQwb0ZYVQSgipgLjeC8AuyQSK9S544RIF2IYimowtkcH01yH0s'}).then((currentToken) => {
    if (currentToken) {
      return currentToken
      //setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      //setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
//const analytics = getAnalytics(app);
export  const db = getDatabase(app);
export default app