import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { serialize } from 'php-serialize';
import api from '../../../constants/api';

export default function EditSpare({ toast, selectedSpare,total,cost,id,disabled }) {
  EditSpare.propTypes = {
    selectedSpare: PropTypes.any,
    total: PropTypes.any,
    cost: PropTypes.any,
    id: PropTypes.any,
    toast:PropTypes.any,
    disabled:PropTypes.any
  };

  const [spare, setSpareObj] = React.useState({
    name: '',
    price: '',
    qty: '',
    per: '',
    id: Math.floor(Math.random() * 90000) + 10000,
    total: 0,
  });
  /* eslint-disable */
  class stdClass {
    constructor() {
      this.name = '';
      this.price = 0;
      this.per = 0;
      this.id = 0;
      this.total = 0;
      this.qty = 0;
      this.e = '';
    }
    serialize() {
      return JSON.stringify({
        name: this.name,
        price: this.price,
        per: this.per,
        id: this.id,
        total: this.total,
        qty: this.qty,
      });
    }
    unserialize(rawData) {
      const { name, price, per, id, total, qty, e } = JSON.parse(rawData);
      this.name = name;
      this.price = price;
      this.per = per;
      this.id = id;
      this.total = total;
      this.qty = qty;
      this.e = e;
    }
  }
  /* eslint-enable */
  const handleChange = (event) => {
    setSpareObj({ ...spare, [event.target.name]: event.target.value });
  };
  const handleSpareParts = async () => {
    if (
      spare.name !== '' &&
      (spare.qty !== '' || spare.qty !== 0) &&
      (spare.price !== '' || spare.price !== 0)
    ) {
      document.getElementById('create-course-form').reset();

      const newtotal =
        parseFloat(spare.price || 0) * parseFloat(spare.qty || 0) +
        (parseFloat(spare.per || 0) / 100) *
          parseFloat(spare.price || 0) *
          parseFloat(spare.qty || 0);
      const prevSpares = [...selectedSpare];
      const newSpare = [...prevSpares, { ...spare, total: newtotal.toFixed(2) }];
      const newcost = parseFloat(spare.price || 0) * parseFloat(spare.qty || 0);
      api.post('/quotation/addSpare',{
        id,
        total:parseFloat(total || 0) + parseFloat(newtotal || 0),
        cost:parseFloat(cost || 0) + parseFloat(newcost || 0),
        spare_parts:serialize(newSpare)
      }).then(res=>{
        console.log(res)
        toast.success('Spare Update')
        window.location.reload()
      }).catch(err=>{
        console.log(err)
        toast.error('Unable to Add Spare')
      })
      
    } else {
      alert('Please fill name qty and price');
    }
  };
  return (
    <>
      <Row style={{ background: 'aliceblue', padding: 5 }}>
        <div className="container mb-2">
          <form id="create-course-form">
            <Row>
              <h5 className="mt-2">Add Spare لابد من الحفظ بعد تسجيل قطع الغيار</h5>
              <Col lg={4} sm={12}>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Name"
                />
              </Col>
              <Col lg={2} sm={12}>
                <input
                  type="text"
                  name="price"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Price"
                />
              </Col>
              <Col lg={2} sm={12}>
                <input
                  type="number"
                  name="qty"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="QTY"
                />
              </Col>
              <Col lg={3} sm={12}>
                <input
                  type="number"
                  name="per"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Percentage"
                />
              </Col>
              <Col lg={1}>
                <button
                  type="button"
                  onClick={handleSpareParts}
                  className="btn btn-secondary mr-2"
                  style={{ marginRight: '2px' }}
                  disabled={disabled}
                >
                  Add
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Row>
    </>
  );
}
