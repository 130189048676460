import React from 'react';

export default function Area() {
  return (
    <>
      <option value="24.435604510729007, 54.41458952208754">ابوظبي</option>
      <option value="24.493383572951544, 54.405576572483874">جزيرة الريم</option>
      <option value="24.418516605736873, 54.34965475555442">الحديريات</option>
      <option value="24.533399024267066, 54.447147031757105">السعديات</option>
      <option value="24.493689735023345, 54.359401395113586">الكورنيش</option>
      <option value="24.414942004245972, 54.57483312053414">خليفة</option>
      <option value="24.43849342212878, 54.50921347660367">ساس النخل</option>
      <option value="24.440903665934158, 54.58030620415417">الراحة </option>
      <option value="24.42921331167959, 54.61986047313373">مصدر </option>
      <option value="24.49780162787939, 54.60460889149172">جزرية ياس</option>
      <option value="24.464966187504345, 54.67419559683441">الريف </option>
      <option value="24.531292983692847, 54.66646946114285">الباهية</option>
      <option value="24.669909726997055, 54.748448606734776">السمحة </option>
      <option value="24.603472230443842, 54.69565392255396">الرحبة </option>
      <option value="24.52969077050288, 54.685528105231825">الشهامه</option>
      <option value="24.22993637746358, 54.65648643070929">الوثبه </option>
      <option value="24.277439648925956, 54.68757809037311">النهضة </option>
      <option value="24.369682098922553, 54.6752709666539">الشوامخ</option>
      <option value="24.44485808782984, 54.72737013170504">الفلاح</option>
      <option value="24.37994124628303, 54.70626599494992">الشامخه </option>
      <option value="24.346770360799617, 54.76463348983213">الرياض </option>
      <option value="24.413146805044935, 54.8738350775274">العدلة</option>
      <option value="24.303471438798063, 54.636138134310094">بني ياس </option>
      <option value="24.367439937348394, 54.63768307909599">شخبوط</option>
      <option value="24.349470213852943, 54.557413333516344">محمد بن زايد </option>
      <option value="24.357587622677634, 54.494063573368074">مصفح الصناعية </option>
      <option value="24.340938202742876, 54.53324619372828">الشعبية </option>
      <option value="24.408160162273745, 54.505287833237276">بين الجسرين</option>
      <option value="24.20933298357927, 55.71134685799537">العين</option>
    </>
  );
}
