import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  Badge,
} from 'reactstrap';
import * as Icon from 'react-feather';
import Swal from 'sweetalert2';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import ComponentCard from '../../../components/ComponentCard';
import api from '../../../constants/api';

const ContactEnquiry = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [enquiries, setEnquiries] = useState();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getAllEnquiries = () => {
    api
      .get('/website/getContactEnquiry')
      .then((res) => {
        setEnquiries(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateEnquiry = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post('/website/updateContact', { id })
          .then(() => {
            Swal.fire('Success!', '.', 'success');
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  React.useEffect(() => {
    getAllEnquiries();

    return () => {
      getAllEnquiries();
    };
  }, []);

  return (
    <>
      <BreadCrumbs />
      <ComponentCard title="Website Contact Enquiry">
        <Nav tabs>
          <NavItem>
            <NavLink
            style={{cursor:'pointer'}}
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => {
                toggle('1');
              }}
            >
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
            style={{cursor:'pointer'}}
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => {
                toggle('2');
              }}
            >
              Responded
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Message</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map((res, index) => (
                        res.wc_status === '0' &&
                        <tr>
                          <td>{index + 1}</td>
                          <th scope="col">{res.wc_name}</th>
                          <th scope="col">{res.wc_phone}</th>
                          <th scope="col">{res.wc_email}</th>
                          <th scope="col">{res.wc_subject}</th>
                          <th scope="col">{res.wc_message}</th>
                          <th scope="col">{res.wc_date_time}</th>
                          <th scope="col">
                            {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                          </th>
                          <th scope="col">
                            <button onClick={()=>{
                              updateEnquiry(res.wc_id)
                            }} className="btn btn-success" type="button">
                              <Icon.CheckSquare></Icon.CheckSquare>
                            </button>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
              <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Message</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map((res, index) => (
                        res.wc_status === '1' &&
                        <tr>
                          <td>{index + 1}</td>
                          <th scope="col">{res.wc_name}</th>
                          <th scope="col">{res.wc_phone}</th>
                          <th scope="col">{res.wc_email}</th>
                          <th scope="col">{res.wc_subject}</th>
                          <th scope="col">{res.wc_message}</th>
                          <th scope="col">{res.wc_date_time}</th>
                          <th scope="col">
                            {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                          </th>
                          <th scope="col">
                            <button className="btn btn-success" type="button">
                              <Icon.CheckSquare></Icon.CheckSquare>
                            </button>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
             
            </Row>
          </TabPane>
        </TabContent>
      </ComponentCard>
    </>
  );
};

export default ContactEnquiry;
