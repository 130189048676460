/* eslint-disable */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import chartData from '../../../views/charts/ChartJsData';
import api from '../../../constants/api';
import { Input } from 'reactstrap';
import DashCard from '../../../components/dashboard/dashboardCards/DashCard';

export default function PackagesOrdered() {
  const [singleMonth, setSingleMonth] = React.useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Users',
        data: chartData.barData.data.b,
        backgroundColor: 'rgb(235 122 53 / 50%)',
      },
    ],
  });
  const [month, setMonth] = React.useState('');
  const numberOfPackagesOrdered = (months) => {
    api
      .post('/dashboard/numberOfPackagesOrdered', {
        month: months,
      })
      .then((res) => {
        let resData = res.data.data;
        let years = [];
        let datas = [];
        resData.forEach((element) => {
          years.push(element.add_booking_name);
          datas.push(element.count);
        });
        setSingleMonth({
          labels: years,
          datasets: [
            {
              label: 'Services',
              data: datas,
              backgroundColor: '#7185c3',
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    numberOfPackagesOrdered('');
  }, []);

  return (
    <>
      <DashCard
        title={`Number of Services BY Month ${month === '' ? moment().format('MMMM') : month}`}
        subtitle=""
        actions={
          <Input
            defaultChecked={moment().format('MM')}
            type="select"
            onChange={(e) => {
                
                setMonth(moment(e.target.value, 'M').format('MMMM'));
              numberOfPackagesOrdered(e.target.value);

            }}
          >
            <option value="">Month</option>
            {Array.apply(0, Array(12)).map(function (_, i) {
              return moment().month(i).format('MMM');
            }) &&
              Array.apply(0, Array(12))
                .map(function (_, i) {
                  return moment().month(i).format('MMM');
                })
                .map((yea, index) => {
                  return <option value={index + 1}>{yea}</option>;
                })}
          </Input>
        }
      >
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Chart.js Bar Chart',
              },
            },
          }}
          data={singleMonth}
        />
      </DashCard>
    </>
  );
}
