import React from 'react';
import { Col, Input, InputGroup, Label, Row, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Area from '../addbooking/Area';

export default function SectionOne({ formData, handleChange, preferredTimeSlots }) {
  SectionOne.propTypes = {
    formData: PropTypes.object,
    handleChange: PropTypes.func,
    preferredTimeSlots: PropTypes.any,
  };
  return (
    <Row>
      <Col lg={3} sm={12}>
        <Label htmlFor="exampleEmail12">رقم الهاتف Phone Number</Label>
        <br></br>
        <InputGroup>
          <Input
            disabled
            name="phone"
            id="phone"
            type="number"
            className="form-control"
            value={formData?.phone}
            onChange={handleChange}
          />
        </InputGroup>
      </Col>
      <Col lg={3} sm={12}>
        <FormGroup>
          <Label for="">
            الأسم (Name) <small className="text-danger">*</small>
          </Label>
          <Input
          disabled
            type="text"
            name="name"
            className="form-control"
            value={formData?.name}
            onChange={handleChange}
          ></Input>
        </FormGroup>
      </Col>

      <Col lg={3} sm={12}>
        <FormGroup>
          <Label>عنوان الرابط (Link Address)</Label>
          <Input
            value={formData?.link}
            onChange={handleChange}
            name="link"
            type="text"
            placeholder=""
          />
        </FormGroup>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">
          التاريخ المفضل Prefered Date: <small className="text-danger">*</small>
        </Label>
        <Input
          type="date"
          name="prefered_date"
          className="form-control"
          value={formData?.prefered_date}
          onChange={handleChange}
        />
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">
          الوقت المفضل (Time): <small className="text-danger">*</small>
        </Label>
        <Input
          type="select"
          value={formData?.prefered_time}
          name="prefered_time"
          onChange={handleChange}
        >
          <option value="">Select Prefered Time</option>
          {preferredTimeSlots &&
            preferredTimeSlots.map((preferredTimeSlot) => (
              <option value={preferredTimeSlot.id}>{preferredTimeSlot.time_zone}</option>
            ))}
        </Input>
        <br></br>
      </Col>

      <Col lg={3} sm={12}>
        <Label for="">
          Lattitude & Longitude <small className="text-danger">*</small>
        </Label>
        <Input
          value={formData?.latlong}
          onChange={handleChange}
          name="latlong"
          id="latlong"
        ></Input>{' '}
      </Col>
      <Col lg={3}>
        <Label>Area</Label>
        <Input type="select" name="latlong" id="" onChange={handleChange}>
          <option value="" disabled selected>
            Select Area
          </option>

          <Area></Area>
        </Input>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">بالقرب من العنوان / Near by</Label>
        <Input type="text" defaultValue={formData?.near_by} className="form-control" name="near_by" onChange={handleChange}></Input>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">
          Payment Mode <small className="text-danger">*</small>
        </Label>
        <Input
          type="select"
          name="payment_mode"
          id="payment-mode"
          className="form-control"
          value={formData?.payment_mode}
          onChange={handleChange}
        >
          <option value="">أختر طريقة دفع</option>
          <option value="cash">Cash</option>
          <option value="bank_transfer">تحويل بنكي-bank transfer/!!!تجنبوها </option>
          <option value="paid_card">Blue Team Card</option>
          <option value="bank_card">Bank Card-بطاقة بنك</option>
        </Input>
      </Col>
      <Col lg={3} sm={12}>
        <div className="form-group">
          <Label for="">رقم المنزل (House no/Villano)</Label>
          <input
            type="text"
            name="email"
            className="form-control"
            onChange={handleChange}
            defaultValue={formData?.email}
          />
        </div>
      </Col>

      <Col lg={3} sm={12}>
        <Label for="">ملاحضات الحجز / notes</Label>
        <textarea
          className="form-control"
          name="note"
          cols="30"
          rows="3"
          onChange={handleChange}
          defaultValue={formData?.note}
        ></textarea>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">العنوان (Address)</Label>
        <textarea
          className="form-control"
          name="address"
          id=""
          cols="30"
          rows="3"
          onChange={handleChange}
          defaultValue={formData?.address}
        ></textarea>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">
          Booking Status /حالة الحجز <small className="text-danger">*</small>
        </Label>
        <select name="booking_status" id="" className="form-control" onBlur={handleChange}>
          <option selected value="confirmed">
            Confirmed
          </option>
          <option value="uncertain">Uncertain</option>
          <option value="delayed">Delayed</option>
        </select>
      </Col>
      <Col lg={3}>
        <h4 className="mt-5" style={{ color: 'red' }}>
          Cost : {formData?.cost || 0}
        </h4>

        <h4 style={{ color: 'red' }}>Total Price : {formData?.total_price}</h4>
        <h4>
          Total With Vat :{' '}
          {parseFloat(formData?.total_price) + 0.05 * parseFloat(formData?.total_price)}
        </h4>
      </Col>
    </Row>
  );
}
