import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Table, Badge } from 'reactstrap';
import * as Icon from 'react-feather';
import Swal from 'sweetalert2';
import $ from 'jquery';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import ComponentCard from '../../../components/ComponentCard';
import api from '../../../constants/api';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';

const BookingEnquiry = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [enquiries, setEnquiries] = useState();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getAllEnquiries = () => {
    api
      .get('/website/getBookingEnquiry')
      .then((res) => {
        if (res.data.data) {
          setEnquiries(res.data.data.reverse());
          $('#completed').DataTable();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getAllEnquiries();

    return () => {
      getAllEnquiries();
    };
  }, []);
  const deleteRecord = (table, column, value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post('/service/deleteRecord', { table, column, value })
          .then(() => {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const updateEnquiry = (id, status) => {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post('/website/updateWebsiteEnquiry', { id, status })
          .then(() => {
            Swal.fire('Success!', '.', 'success');
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  return (
    <>
      <BreadCrumbs />
      <ComponentCard title="Website Booking Enquiry">
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => {
                toggle('1');
              }}
            >
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={activeTab === '4' ? 'active' : ''}
              onClick={() => {
                toggle('4');
              }}
            >
              Progress
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => {
                toggle('2');
              }}
            >
              Converted
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => {
                toggle('3');
              }}
            >
              Not Converted
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Package</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Notes</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map(
                        (res, index) =>
                          res.we_status === '0' && (
                            <tr>
                              <td>{index + 1}</td>
                              <th scope="col">{res.we_category}</th>
                              <th scope="col">{res.we_package}</th>
                              <th scope="col">{res.we_name}</th>
                              <th scope="col">{res.we_phone}</th>
                              <th scope="col">{res.we_email}</th>
                              <th scope="col">
                                {res.we_date},{res.we_time}
                              </th>
                              <th scope="col">{res.we_notes}</th>
                              <th scope="col">
                                {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                              </th>
                              <th scope="col">
                                <button
                                  onClick={() => {
                                    updateEnquiry(res.we_id, '4');
                                  }}
                                  className="btn btn-success"
                                  type="button"
                                >
                                  <Icon.CheckSquare></Icon.CheckSquare>
                                </button>

                                <button
                                  onClick={() => {
                                    deleteRecord('website_enquiry', 'we_id', res.we_id);
                                  }}
                                  className="btn btn-danger"
                                  type="button"
                                >
                                  <Icon.Trash2></Icon.Trash2>
                                </button>
                              </th>
                            </tr>
                          ),
                      )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Package</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map(
                        (res, index) =>
                          res.we_status === '4' && (
                            <tr>
                              <td>{index + 1}</td>
                              <th scope="col">{res.we_category}</th>
                              <th scope="col">{res.we_package}</th>
                              <th scope="col">{res.we_name}</th>
                              <th scope="col">{res.we_phone}</th>
                              <th scope="col">{res.we_email}</th>
                              <th scope="col">
                                {res.we_date},{res.we_time}
                              </th>
                              <th scope="col">
                                {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                              </th>
                              <th scope="col">
                                <button onClick={() => {
                                    updateEnquiry(res.we_id, '1');
                                  }} className="btn btn-success" type="button">
                                  <Icon.CheckSquare></Icon.CheckSquare>
                                </button>
                                <button onClick={() => {
                                    updateEnquiry(res.we_id, '2');
                                  }} className="btn btn-warning" type="button">
                                  x
                                </button>
                              </th>
                            </tr>
                          ),
                      )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <table striped responsive id="completed">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Package</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map(
                        (res, index) =>
                          res.we_status === '1' && (
                            <tr>
                              <td>{index + 1}</td>
                              <th scope="col">{res.we_category}</th>
                              <th scope="col">{res.we_package}</th>
                              <th scope="col">{res.we_name}</th>
                              <th scope="col">{res.we_phone}</th>
                              <th scope="col">{res.we_email}</th>
                              <th scope="col">
                                {res.we_date},{res.we_time}
                              </th>
                              <th scope="col">
                                {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                              </th>
                              <th scope="col">
                                
                              </th>
                            </tr>
                          ),
                      )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Package</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries &&
                      enquiries.map(
                        (res, index) =>
                          res.we_status === '2' && (
                            <tr>
                              <td>{index + 1}</td>
                              <th scope="col">{res.we_category}</th>
                              <th scope="col">{res.we_package}</th>
                              <th scope="col">{res.we_name}</th>
                              <th scope="col">{res.we_phone}</th>
                              <th scope="col">{res.we_email}</th>
                              <th scope="col">
                                {res.we_date},{res.we_time}
                              </th>
                              <th scope="col">
                                {res.wc_status === '0' && <Badge color="danger">Pending</Badge>}
                              </th>
                              <th scope="col">
                                <button onClick={() => {
                                    updateEnquiry(res.we_id, '1');
                                  }} className="btn btn-success" type="button">
                                  <Icon.CheckSquare></Icon.CheckSquare>
                                </button>
                                
                              </th>
                            </tr>
                          ),
                      )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ComponentCard>
    </>
  );
};

export default BookingEnquiry;
