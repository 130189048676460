import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import api from '../../../constants/api';

export default function AddServiceSection({ id, toast, price ,disabled}) {
  AddServiceSection.propTypes = {
    id: PropTypes.any,
    toast: PropTypes.any,
    price: PropTypes.any,
    disabled:PropTypes.any
  };
  const [subCategories, setSubCategories] = React.useState([]);
  const [mainCategories, setMainCategories] = React.useState([]);
  const [currentSelectedCategory, setCurrentSelectedCategory] = React.useState({
    name: '',
    car_type: 'four-wheeler',
    car_no: '',
    id: '',
    price: '',
    time: 0,
    booking_id: '',
    added_by: '',
    total_price: '',
    salon: '',
    fourwheel: '',
  });

  const getSubCategoriesAgainstMainCategory = (mainCategoryId) => {
    api
      .post('/slotbooking/getSubCategory', {
        category_id: mainCategoryId,
      })
      .then((res) => {
        setSubCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMainCategories = () => {
    api.get('/slotbooking/getMainCategories').then((response) => {
      setMainCategories(response.data.data);
      getSubCategoriesAgainstMainCategory('1');
    });
  };
  const handleSubCategoryForPrice = (event) => {
    const selectCategory = event.target.value;

    let packagePrice;
    const subCategoryById = subCategories.find(
      (subCategory) => subCategory.pakage_id === parseInt(selectCategory, 10),
    );
    console.log(currentSelectedCategory?.car_type);
    if (currentSelectedCategory?.car_type === 'salon') {
      packagePrice = subCategoryById?.pakage_salon_price;
    } else {
      packagePrice = subCategoryById?.pakage_four_price;
    }
    setCurrentSelectedCategory({
      ...currentSelectedCategory,
      price: packagePrice,
      salon: subCategoryById?.pakage_salon_price,
      fourwheel: subCategoryById?.pakage_four_price,
      id: selectCategory,
      name: `${subCategoryById?.pakage_name}/${subCategoryById?.pakage_name_arabic}`,
    });
  };

  const handleRowValueChange = (event) => {
    if (event.target.name === 'main_category') {
      getSubCategoriesAgainstMainCategory(event.target.value);
    } else {
      /* eslint-disable */
      if (event.target.name === 'car_type') {
        setCurrentSelectedCategory({
          ...currentSelectedCategory,
          [event.target.name]: event.target.value,
          price:
            event.target.value === 'salon'
              ? currentSelectedCategory.salon
              : currentSelectedCategory.fourwheel,
        });
      } else {
        setCurrentSelectedCategory({
          ...currentSelectedCategory,
          [event.target.name]: event.target.value,
        });
      }
      /* eslint-enable */
    }
  };

  React.useEffect(() => {
    getMainCategories();

    return () => {
      getMainCategories();
    };
  }, []);
  const addServiceToCategory = () => {
    if (
      currentSelectedCategory.name &&
      currentSelectedCategory.id &&
      currentSelectedCategory.price
    ) {
      let userObject = localStorage.getItem('user');
      userObject = JSON.parse(userObject);
      currentSelectedCategory.added_by = userObject.user_retrive_name;
      currentSelectedCategory.booking_id = id;
      currentSelectedCategory.total_price =
        parseFloat(price) + parseFloat(currentSelectedCategory.price);
      console.log(currentSelectedCategory);
      api
        .post('/quotation/addServiceToBooking', currentSelectedCategory)
        .then((response) => {
          if (response.data.status === '200') {
            toast.success('Service added');
            window.location.reload();
          } else {
            toast.warning('Unable to add Service');
          }
        })
        .catch((err) => {
          console.log(err);
         
        });
    } else {
      toast.error('Name Price are required');
    }
  };
  return (
    <>
    <Row className="pb-2 pt-2" style={{ margin: 0, background: 'aliceblue', borderRadius: 5 }}>
      <Col lg={2} sm={12}>
        <Label for="">Main Category</Label>
        <Input
          type="select"
          id="main-category"
          name="mainCategory"
          className="form-control"
          onChange={(e) => {
            getSubCategoriesAgainstMainCategory(e.target.value);
          }}
        >
          <option>Select Main Category</option>
          {mainCategories?.map((category) => {
            if (category.main_id === '1') {
              return (
                <option
                  selected
                  value={category?.main_id}
                >{`${category?.main_name} / ${category?.main_name_arabic}`}</option>
              );
            }
            return (
              <option
                value={category?.main_id}
              >{`${category?.main_name} / ${category?.main_name_arabic}`}</option>
            );
          })}
        </Input>
      </Col>
      <Col lg={2} sm={12}>
        <Label for="">Car Type</Label>
        <Input
          type="select"
          name="car_type"
          id="car_type"
          className="form-control"
          onBlur={handleRowValueChange}
          defaultValue="four-wheeler"
        >
          <option value="four-wheeler">Four Wheeler</option>
          <option value="salon">Salon</option>
        </Input>
      </Col>
      <Col lg={3} sm={12}>
        <Label for="">نوع الحجز (Sub Category) *</Label>
        <Input
          type="select"
          name="id"
          id="id"
          className="form-control"
          onChange={handleSubCategoryForPrice}
        >
          {subCategories?.map((subCategory) => {
            if (subCategory.pakage_name === '1') {
              return (
                <option
                  selected
                  value={subCategory?.pakage_id}
                >{`${subCategory?.pakage_name} / ${subCategory?.pakage_name_arabic}`}</option>
              );
            }

            return (
              <option
                value={subCategory?.pakage_id}
              >{`${subCategory?.pakage_name} / ${subCategory?.pakage_name_arabic}`}</option>
            );
          })}
        </Input>
      </Col>
      <Col lg={2} sm={12}>
        <Label for="">Price</Label>
        <input
          type="text"
          name="price"
          className="form-control"
          placeholder="Price"
          value={currentSelectedCategory.price}
          onChange={handleRowValueChange}
        />
      </Col>
      <Col lg={2} sm={12}>
        <Label for="">Car No</Label>
        <input
          type="text"
          name="car_no"
          className="form-control"
          placeholder="Car No"
          onChange={handleRowValueChange}
        />
      </Col>
      <Col lg={1} sm={12}>
        <button
        disabled={disabled}
          type="button"
          className="btn btn-primary"
          style={{ marginTop: 32 }}
          onClick={() => {
            addServiceToCategory();
          }}
        >
          Add
        </button>
      </Col>
    
    </Row>
      <hr></hr>
      </>
  );
}
