import React from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import { onValue, ref } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import api from '../../../constants/api';
import { db } from '../../../firebase';

export default function MapFilterForm() {
  const [team, setTeam] = React.useState();
  const [bookings, setBookings] = React.useState();
  const [driverLocation, setDriverLocation] = React.useState([]);
  const [showTeam, setShowTeam] = React.useState('');
  const [date, setDate] = React.useState(moment().tz('Asia/Dubai').format('YYYY-MM-DD'));
  const query = ref(db, 'locationsnew');
  const navigate = useNavigate();

  const getDriverHelper = () => {
    api
      .get('/location/getAllEmployees')
      .then((res) => {
        setTeam(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBookings = (formalRange) => {
    api
      .post('/slotbooking/getBookingsForMap', {
        date: formalRange,
      })
      .then((res) => {
        const apiRes = res.data.data;
        const latlong = [];
        apiRes.forEach((element) => {
          const lat = element.latlong && element.latlong.split(',')[0];
          const long = element.latlong && element.latlong.split(',')[1];
          const eid = element.eid?.toString();
          latlong.push({ lat, long, element, eid });
        });

        setBookings(latlong);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchFilterFunction = () => {
    if (showTeam) {
      const newData = driverLocation.filter((item) => {
        const itemData = item.driver_id ? item.driver_id.toUpperCase() : ''.toUpperCase();
        const textData = showTeam.toUpperCase();
        return itemData === textData;
      });

      const newDataBookings = bookings.filter((item) => {
        const itemDataBook = item.eid ? item.eid.toUpperCase() : ''.toUpperCase();
        const textData = showTeam.toUpperCase();
        return itemDataBook === textData;
      });
      const oneTeam = team && team.find((x) => x.id === parseInt(showTeam, 10));
      navigate('/maplocation', {
        state: {
          location: newData,
          bookings: newDataBookings,
          team,
          type: showTeam,
          nextLocation: [oneTeam],
        },
      });
    } else {
      navigate('/maplocation', {
        state: {
          location: driverLocation,
          bookings,
          team,
          type: showTeam,
          nextLocation: team,
        },
      });
    }
  };

  const getFirstTime = () => {
    /* eslint-disable */
    return onValue(
      query,
      (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          Object.values(data).map((project) => {
            setDriverLocation((driverlocation) => [...driverlocation, project]);
          });
        }
      },
      {
        onlyOnce: true,
      },
    );
    /* eslint-enable */
  };

  React.useEffect(() => {
    getDriverHelper();
    getFirstTime();
    return () => {
      getDriverHelper();
      getFirstTime();
    };
  }, []);
  React.useEffect(() => {
    getBookings(date);
  }, [date]);
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg={3}>
            <FormGroup>
              <Label>Team</Label>
              <Input name="date" type="select" onChange={(e) => setShowTeam(e.target.value)}>
                <option value="">All</option>
                {team &&
                  team.map((emp) => {
                    return <option value={emp.id}>{emp.name}</option>;
                  })}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Label>Date</Label>
              <Input
                value={date}
                name="date"
                type="date"
                onChange={(e) => setDate(e.target.value)}
              ></Input>
            </FormGroup>
          </Col>
          <Col lg={3}>
            <Button
              type="button"
              color="dark"
              onClick={() => {
                searchFilterFunction();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
